<template>
  <div v-if="item">
          <div style="margin-top: 20px;padding: 0 10px">
            <translate
              v-model="item.name"
              :languages="languages"
              :label="$t('booking.name', locale)"
              @input="handleChangeData"
              type="text"
            />
          </div>
          <v-select
            v-model="item.type"
            :items="types"
            item-text="name"
            item-value="id"
            :label="$t('dtouch.workspace.totem.type', locale)"
            outlined
            hide-details
            @change="handleChangeData"
            style="margin-top: 20px"
          />
          <div 
            v-if="item.type === 'externalLink'"
            style="margin-top: 20px;padding: 0 10px"
          >
            <translate
              v-model="item.externalLink"
              :languages="languages"
              label="URL"
              @input="handleChangeData"
              type="text"
            />
          </div>
          <div 
            v-if="item.type === 'iframe'"
            style="margin-top: 20px;padding: 0 10px"
          >
            <translate
              v-model="item.iframe"
              :languages="languages"
              label="URL"
              @input="handleChangeData"
              type="text"
            />
          </div>
          <div 
            v-if="item.type === 'pdf'"
            style="margin-top: 20px;padding: 0 10px"
          >
            <translate
              type="file"
              v-model="item.itemPDF"
              :languages="languages"
              @input="handleChangeData"
            />
          </div>
          <v-autocomplete
            v-if="item.type === 'domain'"
            v-model="item.domain"
            :items="dtouchWorkspaces"
            outlined
            item-text="name"
            item-value="id"
            :label="$t('dtouch.workspace.totem.domain', locale)"
            hide-details
            @change="handleChangeData"
            style="margin-top: 20px"
          />
          <v-select
            v-if="item.type === 'activity'"
            v-model="item.bookingWorkspaceID"
            :items="bookingWorkspaces"
            item-text="Name"
            item-value="ID"
            :label="$t('dtouch.workspace.totem.workspace', locale)"
            outlined
            hide-details
            @change="handleChangeData"
            style="margin-top: 20px"
          />
          <v-text-field
            v-if="item.type === 'activity'"
            v-model="item.bookingCategoryFilter"
            outlined
            hide-details
            :label="$t('dtouch.workspace.totem.category', locale)"
            style="margin-top: 20px"
            @change="handleChangeData"
          />
          <v-text-field
            v-if="item.type === 'activityCollege'"
            v-model="item.url"
            outlined
            hide-details
            label="URL"
            style="margin-top: 20px"
            @input="handleChangeData"
          />
          <v-text-field
            v-if="item.type === 'activityCollege'"
            v-model="item.color"
            outlined
            hide-details
            label="Color"
            style="margin-top: 20px"
            @input="handleChangeData"
          />
          <div 
            v-if="item.type === 'imageTranslate'"
            style="margin-top: 10px;padding: 10px;"
          >
            <small>Imagen: </small>
            <br />
            <br />
            <translate
              v-if="itemImageTranslate"
              v-model="itemImageTranslate"
              :languages="languages"
              @input="handleChangeItemImageTranslate"
              type="image"
              :folder="`${workspaceID}/totem`"
              :imageWidth="1080"
              :imageHeight="980"
            />
              <br />
              <small>1080x980px</small>
          </div>
          <div 
            v-if="item.type === 'image'"
            style="margin-top: 10px;padding: 10px;"
          >
            <small>Imagen: </small>
            <br />
            <br />
            <select-image 
              :image="itemImage"
              :onChange="handleChangeItemImage"
              :locale="locale"
              :imageWidth="1080"
              :imageHeight="980"
              :aspectRatio="1"
              :folder="`${workspaceID}/totem`"
            />
              <br />
              <small>1080x980px</small>
          </div>
          <div style="margin-top: 20px;padding: 10px;">
            <small>Icono: </small>
            <br />
            <br />
            <translate
              v-if="image"
              v-model="image"
              :languages="languages"
              label="URL"
              @input="handleChangeImage"
              type="image"
              :folder="`${workspaceID}/totem`"
              :imageWidth="600"
              :imageHeight="600"
              :aspectRatio="1"
            />
            <!--select-image-translate 
              :content="content"
              :languages="languages"
              :image="image"
              :folder="`${workspaceID}/totem`"
            />
            <select-image 
              :image="image"
              :onChange="handleChangeImage"
              :locale="locale"
              :imageWidth="600"
              :imageHeight="600"
              :aspectRatio="1"
              :folder="`${workspaceID}/totem`"
            /-->
          </div>
  </div>
</template>
<script>
import Translate from '@/components/Translate'
import SelectImage from '@/components/selectImage/Index'
import api from '@/services/api'
export default {
  name: 'item',
  components: {
    Translate,
    SelectImage,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    workspaceID: {
      type: String,
      required: true,
    },
    editedItem: {
      type: Object,
      default: null,
    },
    dtouchWorkspaces: {
      type: Array,
      required: true,
    },
    onChangeData: {
      type: Function,
      default: null,
    },
    locale: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    content: { es: '' },
    image: null,
    itemImage: null,
    itemImageTranslate: null,
    item: null,
    languages: [ 'es', 'en', 'de' ],
    types: [
      {
        id: null,
        name: '',
      },
      {
        id: 'domain',
        name: 'webapp',
      },
      {
        id: 'meteo',
        name: 'meteo',
      },
      {
        id: 'activity',
        name: 'actividades',
      },
      {
        id: 'activityCollege',
        name: 'actividades colegios',
      },
      {
        id: 'aena',
        name: 'Aena Tenerife',
      },
      {
        id: 'aenaLanzarote',
        name: 'Aena Lanzarote',
      },
      {
        id: 'aenaFuerteventura',
        name: 'Aena Fuerteventura',
      },
      {
        id: 'externalLink',
        name: 'externalLink',
      },
      {
        id: 'imageTranslate',
        name: 'image T.',
      },
      {
        id: 'image',
        name: 'image',
      },
      {
        id: 'pdf',
        name: 'pdf',
      },
      {
        id: 'iframe',
        name: 'iframe',
      },
    ],
    bookingWorkspaces: [],
  }),
  mounted () {
    this.getBookingGetWorkspaces()
    this.prepareItem()
  }, 
  methods: {
    getBookingGetWorkspaces () {
      api.getAll('dtouch', `v1/private/workspaces/${this.workspaceID}/contentmanager-book/`)
        .then(response => {
          this.bookingWorkspaces = response
        })
    },
    prepareMenu (v) {
      if (!v.image) {
        v.image = { es: '' }
        return v
      }
      if (typeof v.image === 'string') {
        if (v.image.indexOf('{') == -1) {
          v.image = {es:v.image}
        }
        else v.image = JSON.parse(v.image)
      }

      return v
    },
    prepareItem () {
      //if (this.editedItem.type === 'pdf') this.editedItem.itemPDF = this.editedItem.itemPDF
      //this.image = this.editedItem.image
      this.image = this.prepareMenu(this.editedItem).image
      this.itemImage = this.editedItem.itemImage
      this.itemImageTranslate = this.editedItem && this.editedItem.itemImageTranslate ? this.editedItem.itemImageTranslate : { es: '' }
      const aux = JSON.parse(JSON.stringify(this.editedItem))
      if (!aux.externalLink) aux.externalLink = { es: '' }
      this.item = aux
    },
    handleChangeItemImage (v) {
      if (!v) return
      this.itemImage = v.base64
      this.item.newItemImage = v //'https://social.infoten.es/Fichas/PublishingImages/capture_03112013_152110.jpg'
      this.handleChangeData()
    },
    handleChangeItemImageTranslate () {
      this.item.itemImageTranslate = this.itemImageTranslate
      this.handleChangeData()
    },
    handleChangeImage () {
      this.item.image = this.image
      this.handleChangeData()
      /*
      if (!v) return
      this.image = v.base64
      this.item.newImage = v //'https://social.infoten.es/Fichas/PublishingImages/capture_03112013_152110.jpg'
      this.handleChangeData()
        */
    },
    handleChangeData () {
        const aux = this.dtouchWorkspaces.filter(x => x.id === this.item.domain).shift()
        this.item.action = this.item.type === 'domain' && aux ? `@${aux.domain}`
                      : this.item.type === 'externalLink' ? `#${JSON.stringify(this.item.externalLink)}`
                      : this.item.type === 'iframe' ? `ç${JSON.stringify(this.item.iframe)}`
                      : this.item.type === 'pdf' ? 'pdf'
                      : this.item.type === 'meteo' ? ':meteo'
                      : this.item.type === 'aena' ? ':aena'
                      : this.item.type === 'image' ? ':image'
                      : this.item.type === 'imageTranslate' ? ':imageTranslate'
                      : this.item.type === 'aenaLanzarote' ? ':aenaLanzarote'
                      : this.item.type === 'aenaFuerteventura' ? ':aenaFuerteventura'
                      : this.item.type === 'activityCollege' ? ':activityCollege'
                      : this.item.type === 'activity' ? `:activity` : null
      this.onChangeData(this.index, this.item)
    }
  },
}
</script>

